/* COLORS */

$orange: #f09c00;
$green: #009e08;
$grey: #262925;

$dark_blue: #041E42;
$dark_blue_85: rgba(4,30,66,0.75);

$white: #ffffff;
$white_75: rgba(255,255,255,0.35);

$black: $dark_blue;
$black_85: rgba(0,0,0,0.85);

