@font-face {
  font-family: 'Akrobat';
  src: url('../../fonts/akrobat.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Primetime';
  src: url('../../fonts/primetime.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Bebas';
  src: url('../../fonts/BebasNeue-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/BebasNeue-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/BebasNeue-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/BebasNeue-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

$special: 'Bebas';
$normal: 'Akrobat';

  /*
  @font-face {
  font-family: 'MyWebFont';
  src: url('webfont.eot'); /* IE9 Compat Modes 
  src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 
       url('webfont.woff2') format('woff2'), /* Super Modern Browsers 
       url('webfont.woff') format('woff'), /* Pretty Modern Browsers 
       url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS 
       url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS 
}
  */