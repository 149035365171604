@import "../../vars/vars";

.goup {
  position: fixed;
  right: 20px;
  bottom: 25px;
  width: 40px;
  height: 40px;
  z-index: 100;
  border-radius: 99px;
  @include transition(opacity, 200ms);
  box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.hidden {
  opacity: 0;
}
.arrow {
  width: 60px;
  height: 60px;
  transform: rotate(-90deg) translateY(-50%);
  position: absolute;
  left: 50%;
  cursor: pointer;
}

.nahoru {
  color: $black_85;
  font-size: 15px;
  position: absolute;
  top: 48%;
  left: 51%;
  transform: translate(-50%, -50%);
}


