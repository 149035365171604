@import "../../vars/vars";


.page {
    color: white;
    position: absolute;
    font-family: $normal;
    height: 100vh;
    width: 100vw;
    padding: 10px 20px;
    background: $dark_blue;
    text-align: center;
}
.logo {
    width: 100px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.form {
    width: 100%;
    margin: auto;
    max-width: 600px;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    border-radius: 15px;
    overflow: hidden;
    border: 5px white solid;
}


