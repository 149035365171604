@import "../../vars/vars";

.header {
    cursor: pointer;
}
.subtitle {
    margin: 20px 0 6px;
    text-align: center;
    font-size: 22px;
}
.content {
    height: 0;
    overflow: hidden;
    margin-bottom: 10px;
}
.animation {
    width: 100px;
    height: 36px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-top: 0;
}
.rightLine,
.leftLine {
    display: inline-block;
    width: 10px;
    height: 2px;
    border-radius: 100px;
    background: $white;
}
.leftLine {
    transform: rotate(-45deg);
    margin-left: -2px;
}
.rightLine {
    transform: rotate(45deg);
    margin-right: -2px;
}
