@import "../../vars/vars";

.title {
	width: 100%;
	font-size: 45px;
	color: $black;
	font-weight: 700;
	font-family: $normal;
	text-align: center;
	margin: 40px 0 60px;
	padding: 0 20px;
}
.whiteTitle {
	color: $white;
}

.underline {
	margin: auto;
	width: 12px;
	height: 12px;
	margin-top: 16px;
	background: $black;
	border-radius: 99px
}
.whiteUnderline {
	background: $white;
}

@media (min-width: $breakpoint_1) {

}
@media (min-width: $breakpoint_2) {
	.title {
		margin: 60px 0;
	}
}
@media (min-width: $breakpoint_3) {

}
@media (min-width: $breakpoint_4) {

}
@media (min-width: $breakpoint_5) {

}