@import "../../vars/vars";

.contact {
    background: $white;
    color: $black;
    margin: 30px 20px 40px;
    box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    padding: 30px 15px 15px;
    border-radius: 5px;
}

.contact_iconbox {
    width: 50px;
    height: 50px;
    background: $black;
    border-radius: 100%;
    text-align: center;
    padding-top: 12px;
    position: absolute;
    top: -25px;
    left: calc(50% - 25px);
}
.contact_iconbox_icon {
    font-size: 22px;
    margin: auto;
    color: $white;
}

.contact_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 2px;
}
.contact_line {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: center;
}
.contact_line_grey {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    text-align: center;
    color: $grey;
    text-transform: uppercase;
}
.contact_line_bold {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

@media (min-width: $breakpoint_1) {
    .contact_title {
        font-size: 26px;
        margin-bottom: 5px;
    }
    .contact_line_grey {
        font-size: 16px;
        margin-bottom: 14px;
    }
    .contact_line {
        font-size: 20px;
    }
    .contact_line_bold {
        font-size: 21px;
    }
}
@media (min-width: $breakpoint_2) {
}
@media (min-width: $breakpoint_3) {
}
@media (min-width: $breakpoint_4) {
}
@media (min-width: $breakpoint_5) {
}
.contact_link {
	color: #041e42 !important;
}
.contact_link:hover {
	text-decoration: underline !important;
}
