@import "../../vars/vars";


.container {
	font-family: $normal;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 600px;
	margin: 70px 0;
	overflow-x: hidden;
	padding: 10px;


	h2 {
		font-size: 40px;
		margin: 20px 0;
	}
	h3 {
		margin: 10px 0;
		font-size: 30px;
	}
}




//
//.editCardIcon {
//	color: black;
//}