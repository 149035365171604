@import "../../vars/vars";


.button {
	padding: 0 10px;
	line-height: 30px;
	text-align: center;
	border: 1px solid black;
	border-radius: 5px;
	display: inline-block;
	margin: 10px 0 15px 10px;
	cursor: pointer;
}


.prodej {
    background: #009e087c;
}
.realizovano {
    background: #2629255e;
}
.pripravujeme {
    background: #ffa6007a;
}