@import "../../vars/vars";

.input {
    box-sizing: border-box;
    width: calc(100% - 20px);
	color: #041E42;
	padding: 0 20px;
	height: 40px;
	margin: 0 10px 5px 10px;
	border-radius: 5px;
	border: 1px #041E42 solid;
}
