@import "../../vars/vars";

@media (min-width: $breakpoint_2) {
	.cards {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
