@import "../../vars/vars";

.managementCards {
	text-align: center;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin: 60px 0 60px;
}
.reality {
	margin: 60px 26px 60px 10px;
	padding: 1px 0 60px;
	font-size: 19px;
	text-align: center;
	//background: #f0f3f7;
	color: $black;
	border-radius: 6px;
	box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
}
.link {
	text-decoration: underline !important;
	color: $black;
	cursor: pointer;
}
.sreality {
	height: 30px;
	margin-bottom: -7px;
}

@media (min-width: $breakpoint_2) {
	.serviceCard {
		margin-top: 70px;
	
	}
	.companies {
		margin: 0px auto 40px;
	}
}
@media (min-width: $breakpoint_4) {
	.managementCards {
		margin: 100px 0 100px;
	}
	.managementCard {
		width: calc(50% - 30px); 
	}
	.reality {
		font-size: 26px;
		margin-top: 90px;
		margin-bottom: 90px;
	}
	
	.serviceCard {
		width: calc(25% - 20px);
		margin: 40px 10px 0 10px;
		padding-left: 15px;
		padding-right: 15px;
	}
}