@import "../../vars/vars";


.page {
	color: white;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	a {
		color: white;
		text-decoration: underline !important;
	}
}
.title {
	font-size: 25px;
	margin-bottom: 20px;
}
.form {
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.input {
	color: white;
	padding: 0 10px;
	height: 40px;
	background: transparent;
	margin-bottom: 10px;
	border-radius: 5px;
	border: 1px white solid;
}