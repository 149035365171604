@import "../../vars/vars";

.pageContainer {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  font-family: $normal;
  background: $white;
}


