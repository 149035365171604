	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/03/2019 07:13
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-arrow:before { content: "\f100"; }
.flaticon-time:before { content: "\f101"; }
.flaticon-calendar:before { content: "\f102"; }
.flaticon-paint:before { content: "\f103"; }
.flaticon-save:before { content: "\f104"; }
.flaticon-print:before { content: "\f105"; }
.flaticon-video-camera:before { content: "\f106"; }
.flaticon-screenshot:before { content: "\f107"; }
.flaticon-gear:before { content: "\f108"; }
.flaticon-reply:before { content: "\f109"; }
.flaticon-pencil:before { content: "\f10a"; }
.flaticon-filter:before { content: "\f10b"; }
.flaticon-send:before { content: "\f10c"; }
.flaticon-hide:before { content: "\f10d"; }
.flaticon-eye:before { content: "\f10e"; }
.flaticon-price:before { content: "\f10f"; }
.flaticon-document:before { content: "\f110"; }
.flaticon-folder:before { content: "\f111"; }
.flaticon-photograph:before { content: "\f112"; }
.flaticon-star:before { content: "\f113"; }
.flaticon-user:before { content: "\f114"; }
.flaticon-move:before { content: "\f115"; }
.flaticon-crop:before { content: "\f116"; }
.flaticon-link:before { content: "\f117"; }
.flaticon-key:before { content: "\f118"; }
.flaticon-graphic:before { content: "\f119"; }
.flaticon-monitor:before { content: "\f11a"; }
.flaticon-business-and-finance:before { content: "\f11b"; }
.flaticon-information:before { content: "\f11c"; }
.flaticon-photo-camera:before { content: "\f11d"; }
.flaticon-graduation-cap:before { content: "\f11e"; }
.flaticon-twitter-logo-silhouette:before { content: "\f11f"; }
.flaticon-smartphone-call:before { content: "\f120"; }
.flaticon-cogwheel:before { content: "\f121"; }
.flaticon-eye-1:before { content: "\f122"; }
.flaticon-interface:before { content: "\f123"; }
.flaticon-envelope:before { content: "\f124"; }
.flaticon-desktop-monitor:before { content: "\f125"; }
.flaticon-dropbox-logo:before { content: "\f126"; }
.flaticon-cup:before { content: "\f127"; }
.flaticon-magnifying-glass:before { content: "\f128"; }
.flaticon-close:before { content: "\f129"; }
.flaticon-download-to-storage-drive:before { content: "\f12a"; }
.flaticon-thumbs-up-hand-symbol:before { content: "\f12b"; }
.flaticon-computer-tablet:before { content: "\f12c"; }
.flaticon-upload:before { content: "\f12d"; }
.flaticon-tag-black-shape:before { content: "\f12e"; }
.flaticon-vk-social-network-logo:before { content: "\f12f"; }
.flaticon-check-sign-in-a-rounded-black-square:before { content: "\f130"; }
.flaticon-two-circling-arrows:before { content: "\f131"; }
.flaticon-arrow-down-sign-to-navigate:before { content: "\f132"; }
.flaticon-arrow-point-to-right:before { content: "\f133"; }
.flaticon-navigate-up-arrow:before { content: "\f134"; }
.flaticon-arrowhead-thin-outline-to-the-left:before { content: "\f135"; }
.flaticon-youtube-logo:before { content: "\f136"; }
.flaticon-sharethis-logo:before { content: "\f137"; }
.flaticon-lightbulb:before { content: "\f138"; }
.flaticon-bars-chart-down:before { content: "\f139"; }
.flaticon-rising-bar-graph-with-arrow-up:before { content: "\f13a"; }
.flaticon-shuffle-arrows-hand-drawn-symbol:before { content: "\f13b"; }
.flaticon-time-1:before { content: "\f13c"; }
.flaticon-verification-checkmark-symbol:before { content: "\f13d"; }
.flaticon-black:before { content: "\f13e"; }
.flaticon-one-finger-click-black-hand-symbol:before { content: "\f13f"; }
.flaticon-team:before { content: "\f140"; }
.flaticon-social:before { content: "\f141"; }
.flaticon-google-drive-logo:before { content: "\f142"; }
.flaticon-copy-content:before { content: "\f143"; }
.flaticon-crop-tool-button:before { content: "\f144"; }
.flaticon-calendar-1:before { content: "\f145"; }
.flaticon-interface-1:before { content: "\f146"; }
.flaticon-interface-2:before { content: "\f147"; }
.flaticon-technology:before { content: "\f148"; }
.flaticon-distribution:before { content: "\f149"; }
.flaticon-passage-of-time:before { content: "\f14a"; }
.flaticon-waste-bin:before { content: "\f14b"; }
.flaticon-social-1:before { content: "\f14c"; }
.flaticon-add-song:before { content: "\f14d"; }
.flaticon-cancel-music:before { content: "\f14e"; }
.flaticon-sent-mail:before { content: "\f14f"; }
.flaticon-security-on:before { content: "\f150"; }
.flaticon-zoom-button:before { content: "\f151"; }
.flaticon-linked-in-logo:before { content: "\f152"; }
.flaticon-financial:before { content: "\f153"; }
.flaticon-percent:before { content: "\f154"; }
.flaticon-google-sheets:before { content: "\f155"; }
.flaticon-pointer:before { content: "\f156"; }
.flaticon-star-1:before { content: "\f157"; }
.flaticon-shopping-basket:before { content: "\f158"; }
.flaticon-safebox:before { content: "\f159"; }
.flaticon-play-button:before { content: "\f15a"; }
.flaticon-more:before { content: "\f15b"; }
.flaticon-youtube:before { content: "\f15c"; }
.flaticon-signs:before { content: "\f15d"; }
.flaticon-move-1:before { content: "\f15e"; }
.flaticon-copy:before { content: "\f15f"; }
.flaticon-speedometer:before { content: "\f160"; }
.flaticon-logout:before { content: "\f161"; }
.flaticon-technology-1:before { content: "\f162"; }
.flaticon-shopping-list:before { content: "\f163"; }
.flaticon-adobe:before { content: "\f164"; }
.flaticon-excel:before { content: "\f165"; }
.flaticon-powerpoint:before { content: "\f166"; }
.flaticon-interface-3:before { content: "\f167"; }
.flaticon-google-plus:before { content: "\f168"; }
.flaticon-lock:before { content: "\f169"; }
.flaticon-edit:before { content: "\f16a"; }
.flaticon-alarm:before { content: "\f16b"; }
.flaticon-chat:before { content: "\f16c"; }
.flaticon-lock-1:before { content: "\f16d"; }
.flaticon-paste:before { content: "\f16e"; }
.flaticon-push-pin:before { content: "\f16f"; }
.flaticon-smartphone:before { content: "\f170"; }
.flaticon-menu:before { content: "\f171"; }
.flaticon-contact:before { content: "\f172"; }
.flaticon-thunderbolt:before { content: "\f173"; }
.flaticon-wrench:before { content: "\f174"; }
.flaticon-shield:before { content: "\f175"; }
.flaticon-followers:before { content: "\f176"; }
.flaticon-shopping-cart:before { content: "\f177"; }
.flaticon-heart:before { content: "\f178"; }
.flaticon-internet:before { content: "\f179"; }
.flaticon-idea:before { content: "\f17a"; }
.flaticon-scale:before { content: "\f17b"; }
.flaticon-university:before { content: "\f17c"; }
.flaticon-cafe:before { content: "\f17d"; }
.flaticon-lipstick-with-cover:before { content: "\f17e"; }
.flaticon-restaurant:before { content: "\f17f"; }
.flaticon-reception:before { content: "\f180"; }
.flaticon-cardiology:before { content: "\f181"; }
.flaticon-outlook:before { content: "\f182"; }
.flaticon-csv:before { content: "\f183"; }
.flaticon-google-analytics:before { content: "\f184"; }
.flaticon-balloons:before { content: "\f185"; }
.flaticon-tie:before { content: "\f186"; }
.flaticon-recovery-arrow:before { content: "\f187"; }
.flaticon-keyboard:before { content: "\f188"; }
.flaticon-document-1:before { content: "\f189"; }
.flaticon-support:before { content: "\f18a"; }
.flaticon-onedrive:before { content: "\f18b"; }
.flaticon-left-align:before { content: "\f18c"; }
.flaticon-center-align:before { content: "\f18d"; }
.flaticon-right-align:before { content: "\f18e"; }
.flaticon-bold:before { content: "\f18f"; }
.flaticon-italic:before { content: "\f190"; }
.flaticon-underline:before { content: "\f191"; }
.flaticon-broken-link:before { content: "\f192"; }
.flaticon-pie-chart:before { content: "\f193"; }
.flaticon-unlink:before { content: "\f194"; }
.flaticon-picture:before { content: "\f195"; }
.flaticon-group:before { content: "\f196"; }
.flaticon-dashboard:before { content: "\f197"; }
.flaticon-energy:before { content: "\f198"; }
.flaticon-security:before { content: "\f199"; }
.flaticon-list-multiple-choice:before { content: "\f19a"; }
.flaticon-list-order:before { content: "\f19b"; }
.flaticon-list-single-choice:before { content: "\f19c"; }
.flaticon-list-static:before { content: "\f19d"; }
.flaticon-list-matrix-single:before { content: "\f19e"; }
.flaticon-list-matrix-multiple:before { content: "\f19f"; }
.flaticon-list-matrix-text:before { content: "\f1a0"; }
.flaticon-list-scale:before { content: "\f1a1"; }
.flaticon-list-semantic:before { content: "\f1a2"; }
.flaticon-list-text:before { content: "\f1a3"; }
.flaticon-list-picture:before { content: "\f1a4"; }
.flaticon-list-stars:before { content: "\f1a5"; }
.flaticon-list-nps:before { content: "\f1a6"; }
.flaticon-list-number:before { content: "\f1a7"; }
.flaticon-right-arrow:before { content: "\f1a8"; }
.flaticon-left-arrow:before { content: "\f1a9"; }
.flaticon-download-arrow:before { content: "\f1aa"; }
.flaticon-up-arrow:before { content: "\f1ab"; }
.flaticon-horizontal-bar-chart:before { content: "\f1ac"; }
.flaticon-spider-chart:before { content: "\f1ad"; }
.flaticon-list:before { content: "\f1ae"; }
.flaticon-thumb-add:before { content: "\f1af"; }
.flaticon-thumb-welcome:before { content: "\f1b0"; }
.flaticon-thumb-thanks:before { content: "\f1b1"; }
.flaticon-design-toggler:before { content: "\f1b2"; }
.flaticon-radio-button:before { content: "\f1b3"; }
.flaticon-crown:before { content: "\f1b4"; }
.flaticon-goal:before { content: "\f1b5"; }
.flaticon-house:before { content: "\f1b6"; }