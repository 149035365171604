@import "../../vars/vars";

.editDropdown {
    margin: 0 10px;
    position: relative;
    border: 1px solid #041E42;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    min-height: 40px;
    @include transition(border-color, 200ms);
}
.editCardTitle {
    font-weight: 700;
}
.icons {
    position: absolute;
    top: 4px;
    right: 0;
    @include transition(color, 200ms);
}
.editCardIcon {
    font-size: 25px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
    @include transition(color, 150ms);
}
.dropdown {
    padding-top: 10px;
    visibility: hidden;
    opacity: 0;
    height: 0;
}

.prodej {
    background: #009e087c;
}
.realizovano {
    background: #2629255e;
}
.pripravujeme {
    background: #ffa6007a;
}

.editDropdown:hover {
    border-color: blue;

}
.editCardIcon:hover {
    color: rgba(0, 0, 0, 0.472)
}