@import "../../vars/vars";

.link {
    color: $white;
    position: relative;
    font-size: 14px;
    font-family: $normal;
    text-align: left;
    text-transform: uppercase;

    display: block;
    padding: 15px 25px;
    cursor: pointer;
}
.underline {
    visibility: hidden;
    opacity: 0;
}

@media (min-width: $breakpoint_3) {
    .link {
        display: inline-block;
        padding: 0px 10px;
        cursor: pointer;
        font-size: 17px;
    }
    .underline {
        background: $white;
        width: 0%;
        height: 1px;
        opacity: 1;
        margin: auto;
        margin-top: 6px;
        visibility: visible;
    }
}