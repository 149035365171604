@import "../../vars/vars";


.header {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    padding: 0 40px;
    background: $dark_blue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.icons {
    margin-top: 20px;
}

.icon {
    color: white;
    font-size: 30px;
    margin-left: 30px;
    cursor: pointer;

    @include transition(color, 200ms);
}

.logo {
    width: 80px;
    margin-top: 0px;
}

.icon:hover {
    color: rgba(255, 255, 255, 0.513)
}