@import "../../vars/vars";

.contacts {
}

@media (min-width: 700px) {
    .contacts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
    }
    .contacts_item {
        width: 50%;
        display: inline-flex;
        flex-direction: column;
    }
}
