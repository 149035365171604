@import "../../vars/vars";

.introImage {
	background: $dark_blue;
	width: 100%;
	height: 100vh;

	position: relative;
	background: url('assets/bg.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-attachment: scroll;
}

.overlay {
	width: 100%;
	height: 100%;
	background: rgba($color: #000000, $alpha: 0.5);
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 320px;
}

@media (min-width: $breakpoint_1) {
	.overlay {
		background-size: 460px;
	}
}
@media (min-width: $breakpoint_2) {
	.overlay {
		background-size: 560px;
	}
}
@media (min-width: $breakpoint_3) {
	.introImage {
		background-attachment: fixed;
	}
	.overlay {
		background-size: 640px;
	}
}
@media (min-width: $breakpoint_4) {
	.overlay {
		background-size: 780px;
	}
}
@media (min-width: $breakpoint_5) {
	.overlay {
		background-size: 900px;
	}
}