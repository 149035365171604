@import "../../vars/vars";

.footer {
    margin-top: 40px;
    padding: 30px 20px 30px;
    background-color: $black;
    background-image: linear-gradient(#041e42, #000000);
    color: $white;
    font-family: $normal;
}
.m3logo {
    height: 40px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
}
.subtitle {
    margin: 20px 0 12px;
    text-align: center;
    font-size: 22px;
}
.info {
    margin-bottom: 7px;
}
.rubric {
    line-height: 26px;
    text-align: center;
    max-width: 570px;
    margin: auto;
    font-size: 18px;
    line-height: 150%;
}
.more {
    text-align: center;
}
.link {
    text-decoration: underline !important;
    color: $white;
    cursor: pointer;
    line-height: 30px;
}

.animation {
    width: 100px;
    height: 36px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-top: 0;
    cursor: pointer;
}
.rightLine,
.leftLine {
    display: inline-block;
    width: 10px;
    height: 2px;
    border-radius: 100px;
    background: $white;
}
.leftLine {
    transform: rotate(-45deg);
    margin-left: -2px;
}
.rightLine {
    transform: rotate(45deg);
    margin-right: -2px;
}

@media (min-width: $breakpoint_4) {
    .managementCards {
        //display: inline-block;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 100px 0 100px;
    }
    .managementCard {
        width: calc(50% - 30px);
    }
    .gdpr {
        line-height: 26px;
        letter-spacing: 0.5px;
    }
    .footer {
        margin-top: 40px;
        padding: 30px 20px 60px;
    }
}
