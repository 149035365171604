@import "../../vars/vars";


.page {
	padding: 20px;
	position: relative;
}
.icon {
	position: fixed;
	top: 10px;
	right: 10px;
	color: white;
	font-size: 20px;
	cursor: pointer;
	z-index: 100;
}
.email {
	font-size: 16px;
}