@import "../../vars/vars";

.header {
    width: 100%;
    min-height: 77px;
    letter-spacing: 1.3px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding-top: 25px;
    font-family: $normal;
    text-align: center;
}
.mobileControls {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.containerMenu {
    visibility: hidden;
    height: 0;
    opacity: 0;
    position: relative;
    left: 20px;
    width: calc(100% - 40px);
    overflow: hidden;
}
.menu {
    background: $dark_blue_85;
    border-radius: 1px;
    border-top: 1px solid $white_75;
    padding: 5px 0;
}
.menuMobile {
    visibility: hidden;
    opacity: 0;
    display: none;
}

.logo {
    width: 80px;
    height: 60px;
    margin: -10px 0 0 -10px;

    background-size: cover;
    background-repeat: no-repeat;
}
.animation {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 20px 0 10px;
}
.line {
    width: 100%;
    height: 2px;
    background: $white;
    margin-bottom: 7px;
    border-radius: 100px;
}

@media (min-width: $breakpoint_3) {
    .containerMenu {
        display: none;
    }
    .menuMobile {
        display: inline-block;
        padding-top: 15px;
        font-size: 20px;
        visibility: visible;
        opacity: 1;
    }
    .menu {
        display: none;
    }
    .animation {
        // display: none;
        visibility: hidden;
        opacity: 0;
    }
}
