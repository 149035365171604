@import "../../vars/vars";

.card {
    background: $white;
    color: $black;
    margin: 30px 20px 0;
    box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
}
.extraBorders {
    margin: 80px 20px 0;
    padding: 55px 10px 10px;
}
.iconBox {
    width: 80px;
    height: 80px;
    background: $black;
    border-radius: 100%;
    text-align: center;
    padding-top: 18px;
    position: absolute;
    top: -40px;
    left: calc(50% - 40px);
}
.icon {
    font-size: 35px;
    margin: auto;
    color: $white;
}
.tie {
    margin-left: 24px;
}
.title {
    color: $black;
    font-family: $normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
}
.subtitle {
    color: $black;
    font-size: 17px;
    margin-bottom: 7px;
}

.address {
    font-size: 18px;
    margin-bottom: 7px;
    font-weight: 500;
    text-align: center;
    padding: 0 20px;
}
.email {
    font-weight: 600;
    text-align: center;
    padding: 0 20px;
    font-size: 18px;
    margin-bottom: 15px;
}
.userEmail {
    text-align: center;
    padding: 0 20px;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
}

// Projekty
.image {
    padding-top: 59%;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    border-radius: 5px 5px 0 0;
}

.flag {
    color: $white;
    font-weight: 600;
    padding: 6px 0;
    width: 200px;
    transform: rotate(-30deg);
    position: absolute;
    top: 20px;
    left: -50px;
}
.prodej {
    background: $green;
}
.pripravujeme {
    background: $orange;
}
.realizovano {
    background: $grey;
}

.servicesContainer {
    padding: 15px;
    text-align: left;
    font-size: 15px;
}
.logo {
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 10px 0;
}
.name {
    color: $white;
    font-weight: 500;
    font-size: 18px;
    position: absolute;
    left: 15px;
    bottom: 34px;
    text-align: left;
}
.place {
    color: $white;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    left: 15px;
    bottom: 16px;
    text-align: left;
}
.cardInfoTitle {
    display: inline-block;
    width: 90px;
    margin-bottom: 5px;
    color: $black;
}
.cardInfo {
    display: inline-block;
    width: calc(100% - 90px);
    font-weight: 600;
    color: $black;
}
.link {
    color: $white;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    padding: 8px 20px;
    margin-top: 20px;
    background: $black;
    border-radius: 4px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.link_inline {
    display: inline-block;
    cursor: pointer;
}

.companyLogo {
    margin: auto;
    margin-top: -35px;
    margin-bottom: 10px;
    width: 240px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 220px;
}

@media (min-width: $breakpoint_1) {
    .title {
        font-size: 26px;
    }
    .extraBorders {
        padding: 55px 20px 20px;
    }
    .address,
    .subtitle {
        font-size: 20px;
        margin-bottom: 9px;
    }
    .email {
        font-size: 21px;
    }
    .name {
        font-size: 26px;
        bottom: 40px;
    }
    .place {
        font-size: 22px;
    }
    .logo {
        margin: 15px 0;
    }
    .servicesContainer {
        padding: 20px;
    }
    .cardInfoTitle {
        font-size: 20px;
        width: 140px;
    }
    .cardInfo {
        font-size: 20px;
        width: calc(100% - 140px);
    }
    .userEmail {
        font-size: 21px;
    }
}
@media (min-width: $breakpoint_2) {
    .card {
        display: inline-block;
        width: calc(50% - 30px);
        margin: 30px 15px 0 15px;
        //min-height: 360px;
        vertical-align: top;
    }
}
@media (min-width: $breakpoint_3) {
}
@media (min-width: $breakpoint_4) {
    .card {
        width: calc(33% - 30px);
        margin: 40px 15px 0 15px;
        //min-height: 360px;
    }
}
@media (min-width: $breakpoint_5) {
}
